<template>
  <div class="pixel-list-wrap">
    <div class="header dp-flex justify-btw">
      <ul class="dp-flex">
        <li v-for="(item, index) in showItems" :key="index">
          <p class="title text-a-c f-12">{{ item.title }}</p>
          <p class="count text-a-c">
            <span v-if="index != 0">$</span
            >{{ index != 0 ? resData[item.prop] / 100 : resData[item.prop]
            }}<span v-if="index == 0">个</span>
          </p>
        </li>
      </ul>
    </div>
    <cus-table
      ref="cusTableRef"
      :cellMouseEnter="cellMouseEnter"
      :cellMouseLeave="cellMouseLeave"
      :tableParams="tableParams"
      :tableHeightData="tableHeightData"
      :tableData="tableData.slice((page - 1) * limit, page * limit)"
      :colData="colData"
    >
      <template v-slot:cusheader="{ scope, col }">
        <div v-if="col.prop == 'statsCount'">
          <el-tooltip
            effect="dark"
            :content="col.headerTooltip"
            placement="top"
          >
            <span>{{ col.label }}<i class="el-icon-question"></i></span>
          </el-tooltip>
        </div>
      </template>
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop == 'isUnavailable'" class="dp-flex align-item-c">
          <i
            :class="[
              !scope.row[prop]
                ? 'el-icon-success color-success'
                : 'el-icon-error color-danger',
              'mr-10',
              'f-16'
            ]"
          ></i
          >{{ scope.row[prop] ? "无效" : "有效" }}
        </div>
        <div v-else-if="prop == 'statsCount'">
          <el-tooltip
            v-if="scope.row[prop]"
            placement="top"
            content="点击查看事件详情"
          >
            <el-button
              @click="handleEventDetail(scope.row)"
              v-if="scope.row[prop]"
              type="text"
              >{{ scope.row[prop] }}
            </el-button>
          </el-tooltip>
          <span v-else>未查询到事件 </span>
        </div>
        <div v-else-if="prop == 'shareAdAccounts'">
          <span v-if="scope.row[prop] && scope.row[prop].length">
            {{ scope.row[prop][0].name
            }}<template v-if="scope.row[prop] && scope.row[prop].length > 1"
              >等{{ scope.row[prop].length }}个广告账号</template
            >
          </span>
          <span v-else>
            <el-tooltip content="授权自BM TEST" placement="top"
              ><i class="el-icon-question color-theme"></i></el-tooltip
            >未绑定广告账号</span
          >
        </div>
        <!-- 名称 -->
        <div v-else-if="prop == 'name'" class="dp-flex justify-btw pr cus-name">
          <p>
            <span class="ad-name">{{ scope.row[prop] }}</span>
          </p>
          <!-- 操作icon -->
          <div v-show="scope.row.showIcon" class="pa row-operate-icon dp-flex">
            <div
              @mouseenter.stop="
                handleMouseEvent({ pindex: scope.$index, index, mark: 'enter' })
              "
              @mouseleave.stop="
                handleMouseEvent({ pindex: scope.$index, index, mark: 'leave' })
              "
              v-for="(item, index) in scope.row.iconData"
              :key="index"
              class="dp-flex justify-c align-item-c"
            >
              <i :class="item.iconClassName"></i>
              <el-button
                @click="handleOperateIcon({ row: scope.row, text: item.text })"
                slot="reference"
                type="primary"
                plain
                size="mini"
                :class="item.textClassName"
                >{{ item.text }}</el-button
              >
            </div>
          </div>
        </div>
      </template>
    </cus-table>
    <el-pagination
      class="text-a-r"
      style="margin-top:16px;"
      background
      @current-change="handleCurrentChange"
      :hide-on-single-page="true"
      layout="prev, pager, next"
      :total="resData.total"
    >
    </el-pagination>
    <!-- 事件详情 -->
    <event-detail-dialog
      @close-dialog="eventDetailVisible = false"
      :visible="eventDetailVisible"
      :initData="eventDetailData"
    ></event-detail-dialog>
  </div>
</template>

<script>
import cusTable from "@/views/components/cusTable";
import eventDetailDialog from "@/views/adAssets/components/eventDetailDialog";
import { getPixels } from "@/api/adAssets";
export default {
  components: {
    cusTable,
    eventDetailDialog
  },
  props: ["id", "isSync"],
  data() {
    return {
      page: 1,
      limit: 10,
      allData: [],
      tableParams: {
        border: true,
        isTableHeight: true
      },
      // 表格
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".cus-tab-wrap>.el-tabs__header", cssProperty: "height" },
        {
          selector: ".cus-tab-wrap>.el-tabs__content",
          cssProperty: "paddingTop"
        },
        {
          selector: ".cus-tab-wrap>.el-tabs__content",
          cssProperty: "paddingBottom"
        },
        { selector: ".pixel-list-wrap>.header", cssProperty: "height" },
        { selector: ".pixel-list-wrap>.header", cssProperty: "marginBottom" }
      ],
      tableData: [],
      colData: [
        { label: "名称", prop: "name", width: "400px" },
        {
          label: "状态",
          prop: "isUnavailable"
        },
        {
          label: "事件信息",
          prop: "statsCount",
          headerTooltip: "最近七天facebook收到的事件数量"
        },
        {
          label: "广告帐号",
          prop: "shareAdAccounts"
        },
        { label: "最近触发时间", prop: "lastFiredTime" }
      ],
      loading: false,
      // 接口返回数据
      resData: { total: 10 },
      // 表格上方数据总览
      showItems: [{ title: "Facebook Pixel", prop: "total" }],
      selectedRow: {},
      // 批量操作-图标
      batchIconData: [
        {
          iconClassName: ["el-icon-document"],
          textClassName: ["dp-none"],
          text: "命名规则"
        },
        {
          iconClassName: ["el-icon-position"],
          textClassName: ["dp-none"],
          text: "转化追踪"
        },
        {
          iconClassName: ["el-icon-s-tools"],
          textClassName: ["dp-none"],
          text: "广告结构"
        }
      ],
      // 查看事件详情
      eventDetailData: {},
      eventDetailVisible: false
    };
  },
  methods: {
    // 当前页改变
    handleCurrentChange(val) {
      this.page = val;
    },
    // 获取广告像素
    getPixels(id) {
      this.$showLoading();
      getPixels({ id }).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          let arr = JSON.parse(JSON.stringify(res.data));
          // 行操作
          // iconData = [
          //   {
          //     iconClassName: ["el-icon-view"],
          //     textClassName: ["dp-none"],
          //     text: "查看详细数据"
          //   }
          // ];
          // arr.forEach(item => {
          //   this.$set(item, "iconData", iconData);
          // });
          this.tableData = arr;
          this.resData.total = res.data ? res.data.length : 10;
          if (this.resData.total > 10) {
            this.$bus.$emit("trigger-height-change-page",40);
          }
        }
      });
    },
    // 点击像素
    handleToPixelPage(scope) {
      this.$emit("topixelpage", scope);
    },
    // 搜索
    handleSearch() {
      // this.ipt
    },
    // 鼠标移入row
    cellMouseEnter(row, column, cell, event) {
      this.$set(row, "showIcon", true);
    },
    // 鼠标移出row
    cellMouseLeave(row, column, cell, event) {
      this.$set(row, "showIcon", false);
    },
    // 鼠标移入移出事件
    handleMouseEvent({ pindex, index, mark }) {
      let obj = {};
      if (pindex != undefined) {
        obj = this.tableData[pindex].iconData[index];
      } else {
        obj = this.batchIconData[index];
      }
      switch (mark) {
        case "enter":
          obj.iconClassName.push("dp-none");
          obj.textClassName = [];
          break;
        case "leave":
          let i = "";
          i = obj.iconClassName.findIndex(item => item == "dp-none");
          obj.iconClassName.splice(i, 1);
          obj.textClassName = ["dp-none"];
          break;
        default:
          break;
      }
    },
    // 行操作icon
    handleOperateIcon({ row, text }) {
      // console.log("handleOperateIcon(row, text)", row, text);
      if (row != undefined) {
        let name = row.name;
        this.selectedRow = row;
      }
      switch (text) {
        // 表格行
        // case "查看详细数据":
        //   break;
        default:
          break;
      }
    },
    // 查看事件详情
    handleEventDetail(row) {
      this.eventDetailData = row;
      this.eventDetailVisible = true;
    }
  },
  mounted() {},
  watch: {
    id: {
      handler(val) {
        // console.log("val:id", val);
        this.getPixels(val);
      },
      immediate: true
    },
    // 当同步成功后重新调用列表接口
    isSync(val) {
      if (val) {
        this.getPixels(this.id);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.pixel-list-wrap {
  color: $theme-color;
  .header {
    margin-bottom: 8px;
    .el-input {
      width: auto;
    }
    li {
      min-width: 150px;
      border-right: 1px solid #e5e6e9;
      padding: 0 15px;
      &:last-child {
        border-right: none;
      }
    }
    .count {
      font-weight: 700;
      margin-top: 10px;
    }
  }
  .batch-oparate-wrap {
    padding: 0 16px;
    margin-bottom: 8px;
    background: rgb(232, 241, 255);
    height: 32px;
    .el-button--mini,
    .el-button--mini.is-round {
      padding: 2px 10px;
    }
  }
  .cus-name {
    .flag-i {
      margin: {
        left: 7px;
        right: 10px;
      }
    }
    .ad-name {
      font-weight: bold;
      &:hover {
        color: $theme;
      }
    }
    .ad-no {
      font-size: 12px;
      color: rgb(185, 187, 190);
      margin-left: 12px;
    }
  }
  .row-operate-icon {
    top: 0;
    right: 56px;
    height: 100%;
    background: #f8f8fa;
    & > div {
      padding: 0 5px;
      min-width: 46px;
      max-width: 120px;
    }
    i {
      font-size: 18px;
    }
    .el-icon-more {
      border: 1px solid $theme;
      border-radius: 50%;
    }
    .el-button--mini {
      padding: 5px 10px;
    }
  }
  .table-self {
    .el-table__body-wrapper {
      height: auto !important;
    }
  }
}
</style>
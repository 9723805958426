// 计算最大值
export function calculateMax(arr) {
    // console.log("calculateMax(arr)", arr);
    let max = 0;
    arr.forEach(item => {
        let _item = Number(item);
        if (max < _item) {
            max = _item;
        }
    });
    if (!max) {
        max = 1;
    }
    // console.log("cal-max", max);
    return max;
}
// 计算最小值
export function calculateMin(arr) {
    let min = 0;
    arr.forEach(item => {
        let _item = Number(item);
        if (min > _item) {
            min = _item;
        }
    });
    // console.log("cal-min", min);
    return min;
}
// 设置坐标轴最大最小值和间隔
export function setAxisMaxMin(axisName, option, splitNumOption) {
    let def = {
        noDataSplitNum: 5,
        hasDataSplitNum: 6,
        ...splitNumOption
    };
    option.series.forEach((item, index) => {
        let max = "",
            min = "",
            splitNumber = "";
        if (item.data.length) {
            max = calculateMax(item.data);
            min = calculateMin(item.data);
            splitNumber = def.hasDataSplitNum;
            option[axisName][index].interval = (max - min) / splitNumber;
        } else {
            max = 1;
            min = 0;
            splitNumber = def.noDataSplitNum;
            delete option[axisName][index].interval;
        }
        // console.log("min,max", min, max);
        option[axisName][index].min = min;
        option[axisName][index].max = max;
        option[axisName][index].splitNumber = splitNumber;
    });
}
// 依据echarts自动生成的坐标轴的刻度范围设置interval
export function setAxisMaxMinV2(chart, option, axisName, splitNumber) {
    let axisArr = chart.getModel()._componentsMap.data[axisName];
    //获取echarts自动生成的坐标轴的刻度范围
    let axisRangeArr = axisArr.map(item => item.axis.scale._extent);
    // console.log("setAxisMaxMinV2=>axisRangeArr", axisRangeArr);
    axisRangeArr.forEach((item, index) => {
        // item[0]-最小值
        // item[1]-最大值
        if (option[axisName] instanceof Array) {
            option[axisName][index].interval = (item[1] - item[0]) / splitNumber;
        } else {
            option[axisName].interval = (item[1] - item[0]) / splitNumber;
        }
    })
}
// 数组扁平化-去重-倒序
export function flatDupSortFn(arr) {
    let fArr = arr.flat()
    let dArr = Array.from(new Set(fArr))
    dArr.sort((a, b) => b - a)
    return dArr
}
// 依据echarts自动生成的坐标轴的刻度范围设置最大最小值-间隔(双grid)-概览页面
export function setAxisMaxMinV3(chart, option, axisName, splitNumber) {
    let axisArr = chart.getModel()._componentsMap.data[axisName];
    //获取echarts自动生成的坐标轴的刻度范围
    let axisRangeArr = axisArr.map(item => item.axis.scale._extent);
    // console.log("setAxisMaxMinV3=>axisRangeArr", axisRangeArr);
    let arr1 = flatDupSortFn(axisRangeArr.slice(0, 2))
    let arr2 = flatDupSortFn(axisRangeArr.slice(2))
    let max1 = arr1[0],
        min1 = arr1[arr1.length - 1];
    let max2 = arr2[0],
        min2 = arr2[arr2.length - 1];
    // console.log("arr1,arr2", arr1, arr2);
    axisRangeArr.forEach((item, index) => {
        let max = "",
            min = ""
        if (index < 2) {
            max = max1
            min = min1
        } else {
            max = max2
            min = min2
        }
        option[axisName][index].max = max;
        option[axisName][index].min = min;
        option[axisName][index].interval = (max - min) / splitNumber;
    })
}

// 依据echarts自动生成的坐标轴的刻度范围设置最大最小值-间隔(双grid)-广告管理下的图表抽屉页面
export function setAxisMaxMinV4(chart, option, axisName, splitNumber) {
    let axisArr = chart.getModel()._componentsMap.data[axisName];
    //获取echarts自动生成的坐标轴的刻度范围
    let axisRangeArr = axisArr.map(item => item.axis.scale._extent);
    // console.log("setAxisMaxMinV4=>axisRangeArr", axisRangeArr);
    let arr1 = flatDupSortFn([axisRangeArr[0], axisRangeArr[2]])
    let arr2 = flatDupSortFn([axisRangeArr[1], axisRangeArr[3]])
    let max1 = arr1[0],
        min1 = arr1[arr1.length - 1];
    let max2 = arr2[0],
        min2 = arr2[arr2.length - 1];
    // console.log("arr1,arr2", arr1, arr2);
    // console.log("max1,min1,max2, min2", max1,min1,max2, min2);
    axisRangeArr.forEach((item, index) => {
        let max = "",
            min = ""
        if (index == 0 || index == 2) {
            max = max1
            min = min1
        } else {
            max = max2
            min = min2
        }
        option[axisName][index].max = max;
        option[axisName][index].min = min;
        option[axisName][index].interval = (max - min) / splitNumber;
    })
}
// 数组中嵌套对象按照对象中某属性降序
export function arrSortFn(arr, objKey) {
    let _arr = [];

    function compare(objKey) {
        return function (a, b) {
            let val1 = a[objKey] ? a[objKey].value : "";
            let val2 = b[objKey] ? b[objKey].value : "";
            return Number(val2) - Number(val1);
        };
    }
    _arr = arr.sort(compare(objKey));
    return _arr;
}
// 数组中嵌套对象按照对象中某属性降序-图表抽屉下的版位分析页面
export function arrSortFnV2(arr, objKey) {
    let _arr = [];

    function compare(objKey) {
        return function (a, b) {
            let val1 = a[objKey] ? a[objKey] : 0;
            let val2 = b[objKey] ? b[objKey] : 0;
            return Number(val2) - Number(val1);
        };
    }
    _arr = arr.sort(compare(objKey));
    return _arr;
}
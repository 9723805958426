<template>
  <el-dialog
    custom-class="event-detail-dialog common-dialog"
    :visible="visible"
    title="查看pixel事件"
    @close="handleClose"
  >
    <el-row class="h100">
      <el-col :span="8" class="p-10 bg-cus h100">
        <div class="circle-line f-12 pr">
          <span class="title pa bg-cus"
            ><i class="el-icon-document"></i>Facebook Pixel</span
          >
          <div class="content">
            <p>
              名称<br /><span>{{ initData.name }}</span>
            </p>
            <p>
              ID<br /><span>{{ initData.id }}</span>
            </p>
            <p>事件总数<br /><span>{{initData.statsCount}}</span></p>
            <p>
              <i class="el-icon-question"></i>
              最近7天收到的Facebook事件数量
            </p>
          </div>
        </div>
      </el-col>
      <el-col class="dp-flex h100" :span="16" style="padding:0 40px;">
        <cus-table
          :tableParams="tableParams"
          :tableData="tableData"
          :colData="colData"
          :loading="loading"
        ></cus-table>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cusTable from "@/views/components/cusTable";
import { getPixelEvents } from "@/api/adAssets";
export default {
  components: { cusTable },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    initData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tableParams: {
        className: "",
        border: false,
        height: "100%"
      },
      tableData: [
       
      ],
      colData: [
        { label: "事件名称", prop: "name" },
        {
          label: "触发数量(最近7天)",
          prop: "count"
        },
        {
          label: "最近触发时间",
          prop: "startTime"
        }
      ],
      loading: false
    };
  },
  methods: {
    // 获取像素下的事件信息
    getPixelEvents(pixelId) {
      this.$showLoading();
      getPixelEvents({ pixelId }).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.tableData = res.data;
        }
      });
    },
    handleClose() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$emit("close-dialog");
    }
  },
  mounted() {},
  watch: {
    "initData.id": {
      handler(val) {
        // console.log("initData.id", val);
        this.getPixelEvents(val);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.event-detail-dialog {
  .el-dialog__header {
    @extend .dp-flex;
    @extend .justify-c;
  }
  .el-dialog__body {
    height: 500px;
    padding: 0;
  }
  .bg-cus {
    background: rgb(245, 245, 247);
  }
  .circle-line {
    border: 1px dashed #b9bbbe;
    padding: 18px 24px;
    border-radius: 4px;
    margin-top: 20px;
    .title {
      top: -15px;
      padding: 5px 10px;
      left: 15px;
      color: rgb(38, 38, 38);
    }
    .content {
      line-height: 20px;
      p {
        @extend .mb-16;
        color: #9da0a3;
        span {
          color: rgb(38, 38, 38);
        }
      }
    }
  }
  .table-self {
    .el-table__row:nth-child(2n) {
      background: initial;
    }
    .el-table__body-wrapper.is-scrolling-none {
      max-height: 500px;
    }
  }
}
</style>